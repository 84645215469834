import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 50.031 45.862 C 50.6917 57.95 46.6917 61 40.2917 61 L 28.5417 61 L 28.5417 30.3 L 38.757 30.321 C 44.8 30.352 49.695 34.799 49.832 41.144 Z Z M 33.5917 47.5 L 33.5917 56.5 L 40.1917 56.5 C 43.6917 56.5 45.5917 54.75 45.915 51.994 C 46.046 49.17 47.885 34.919 39.704 34.817 L 33.612 34.807 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
